import React, { useState } from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';
import DeleteIcon from '../../../Components/Icons/DeleteIcon';
import Loader from '../../../Components/Loader';
import { useDeleteUserMutation } from '../../../data/api/userApi';
import { confirmAlert } from '../../../Components/ConfirmModal';

function DeleteAccountModal({ data, onComplete }) {
  const [deleteUser, { isLoading, error }] = useDeleteUserMutation();

  const [showForm, setShowForm] = useState(false);

  const handleClose = () => {
    setShowForm(false);
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteUser({
        id: data.id,
      }).unwrap();
      confirmAlert({
        title: 'Account has been successfully deleted',
        variant: 'success',
        confirmBtn: false,
        cancelText: 'Ok',
      }).catch(() => {});
      handleClose();
      onComplete?.();
    } catch (_e) {}
  };

  return (
    <>
      <Stack direction="horizontal" gap="2" onClick={() => setShowForm(true)}>
        <DeleteIcon size={20} />
        Delete
      </Stack>
      {showForm && (
        <Modal centered dialogClassName="modal-md" show={showForm} onHide={handleClose} id="update-user-modal">
          <Modal.Header closeButton>
            <Modal.Title>Delete User Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              {isLoading ? (
                <div className="d-flex justify-content-center w-100">
                  <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
                </div>
              ) : (
                <>
                  {error && <div className="alert alert-danger fs-7">{error?.data?.message}</div>}

                  <p>Are you sure you want to delete user: { `${data?.first_name} ${data?.last_name}` }</p>
                </>
              )}
            </>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <div className="ms-auto">
              <Button type="button" variant="outline-light" className=" ms-auto" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="button" disabled={isLoading} variant="warning" className=" ms-3" onClick={handleDeleteAccount} id="delete-btn">
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default DeleteAccountModal;
