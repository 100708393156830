import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SelectAbTestGame from '../_Components/SelectAbTestGame';
import PropTypes from 'prop-types';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import { confirmAlert } from '../../../../Components/ConfirmModal';
import { useCreateGameConfigMutation, useEligibleToJsonMetricsQuery } from '../../../../data/api/abTestsApi';
import { useSetState } from '../../../../utils/customHooks';
import { formatJoiErrorsArray } from '../../../../utils/validation';
import Loader from '../../../../Components/Loader';
import ConfigForm from './ConfigForm';
import configSchema from '../_validationSchemas/configSchema';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../../data/slices/studio';

function CreateConfigModal({ id, initialState, onClose }) {
  const [createGameConfig, { isLoading }] = useCreateGameConfigMutation();
  const [step, setStep] = useState(id ? 2 : 1);
  const [errors, setErrors] = useState({});
  const [grewUp, setGrewUp] = useState(false);

  const initialData = {
    studioId: '',
    country: [],
    id: id || '',
    bundleId: '',
    build: '',
    device: '',
    os: 'all',
    store: [],
    network: '',
    campaigns: [],
    name: '',
    params: [{ name: '', value: '' }],
  };
  const [state, setState] = useSetState({ ...initialData, ...initialState });
  const { data: eligible } = useEligibleToJsonMetricsQuery(state.bundleId, {
    skip: !state.bundleId || step < 2,
  });
  const foundGame = useSelector(getGameById(state.id));

  useEffect(() => {
    if (foundGame && !foundGame?.multipleOs && foundGame?.os !== state?.os) setState({ os: foundGame?.os });
    if (foundGame && !state.bundleId) setState({ bundleId: foundGame?.bundleId });
  }, [foundGame]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrors({});
    }
  }, [state]);

  const handleBack = () => {
    if (step === 1) {
      onClose();
    } else {
      setStep(step - 1);
    }
  };
  const formatStep2Validation = (details) => {
    let error = details.map((err) => {
      if (err.path[0] === 'cohorts' && err.path[2] === 'params') {
        return { path: ['cohorts'], message: 'Cohorts validation failed, Please add at least one' };
      }
      return err;
    });

    return formatJoiErrorsArray(error);
  };
  const handleCreateAbtest = (state) => {
    let postObj = { ...state };
    postObj.gen = eligible?.eligible ? 2 : 1;
    postObj.country = postObj.country.join(',');
    postObj.studioId = foundGame?.studioId;

    createGameConfig(postObj)
      .unwrap()
      .then(() => {
        confirmAlert({
          title: 'You have successfully created config',
          variant: 'success',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
        onClose();
      })
      .catch((e) => {
        if (e?.data?.details) {
          setErrors(formatStep2Validation(e.data.details));
          return false;
        } else {
          setErrors({ error: e?.data?.message || 'Something went wrong.' });
        }
      });
  };
  const handleNext = () => {
    if (step === 1) {
      if (!state.bundleId) {
        return setErrors({ bundleId: 'Game is required' });
      }
    }
    if (step === 2) {
      let tmp = JSON.parse(JSON.stringify(state));
      const { value, error } = configSchema.validate(tmp);

      if (error) {
        return setErrors(formatStep2Validation(error.details));
      }
      return handleCreateAbtest(value);
    }
    setStep(step + 1);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={onClose}
        scrollable={step === 1}
        backdrop="static"
        className={`modal-animate ${grewUp ? 'modal-xl' : 'modal-md'}`}
        id="create-config-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Config</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 300 }}>
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            <>
              {errors?.error && <div className="alert alert-danger fs-7">{errors.error || 'Something went Wrong'}</div>}
              <SelectAbTestGame
                errors={errors}
                gameId={state.id}
                selected={step === 2}
                onSelect={(game) => setState({ bundleId: game.bundleId, id: game.id })}
              />
              {step === 2 && (
                <>
                  {eligible?.eligible === false && (
                    <div className="alert alert-warning mb-3 d-flex align-items-center fs-7" role="alert">
                      <div className="me-3">
                        <InfoIcon />
                      </div>
                      <div>
                        To collect test data, make sure your app embeds "FGAdjust 3.2.4" module or a later version
                      </div>
                    </div>
                  )}

                  <ConfigForm data={state} setData={setState} errors={errors} setGrewUp={setGrewUp} grewUp={grewUp} />
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="text-muted fs-7">Step {step} of 2</div>
          <div className="ms-auto">
            <Button type="button" variant="secondary" className="ms-auto" onClick={handleBack}>
              {step === 2 ? 'Back' : 'Cancel'}
            </Button>
            <Button type="button" variant="primary" className="ms-3" onClick={handleNext} id="create">
              {step === 2 ? 'Create' : 'Next'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateConfigModal;

CreateConfigModal.propTypes = {
  initialState: PropTypes.object,
  id: PropTypes.string,
};
CreateConfigModal.defaultProps = {
  initialState: {},
  defaultOpen: false,
};
