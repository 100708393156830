import { createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import { userApi } from '../api/userApi';
import { getCookie, setCookie } from '../../utils/common';
import permissionsByRole from '../../constants/permissions';

const user = JSON.parse(getCookie('user-id') || '{}');
const initialState = {
  user,
  studioSelected: {},
  userPermissions: permissionsByRole[user.role],
};

export const userSelector = (state) => state.user.user;
export const studioSelectedSelector = (state) => state.user.studioSelected;
export const userPermissionsSelector = (state) => state.user.userPermissions;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setStudioList: (state, action) => {
      state.studioList = action.payload;
    },
    setStudioSelected: (state, action) => {
      state.studioSelected = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        isRejectedWithValue(action) && (action?.payload?.status === 401 || action?.payload?.originalStatus === 401), // detect http 401
      (state, { payload }) => {
        state.user = {}; // clear user data -> GuardedRoute will redirect to /login -> /login clears cookie
        state.userPermissions = [];
      },
    );
    builder.addMatcher(userApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.user = payload || {};
      if (payload?.role) {
        state.userPermissions = permissionsByRole[payload.role] || [];
        setCookie('user-id', JSON.stringify(payload), 1);
      }
    });
    builder.addMatcher(userApi.endpoints.refreshUserData.matchFulfilled, (state, { payload }) => {
      state.user = payload || {};
      if (payload?.role) {
        state.userPermissions = permissionsByRole[payload.role] || [];
        setCookie('user-id', JSON.stringify(payload), 1);
      }
    });
    builder.addMatcher(userApi.endpoints.updateUserProfile.matchFulfilled, (state, { payload }) => {
      setCookie('user-id', JSON.stringify(payload), 1);
      state.user = payload || {};
      state.userPermissions = permissionsByRole[payload.role] || [];
    });
  },
});

export const { setUser, setStudioList, setStudioSelected, setUserPermissions } = userSlice.actions;

export default userSlice.reducer;
