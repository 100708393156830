import moment from 'moment';
import CardIcon from '../../../Components/Icons/CardIcon';
import SettingsIcon from '../../../Components/Icons/SettingsIcon';
import CalendarIcon from '../../../Components/Icons/CalendarIcon';
import EarthIcon from '../../../Components/Icons/EearthIcon';
import MarketInsightIcon from '../../../Components/Icons/MarketInsightIcon';
import HeaderInfoCard from './HeaderInfoCard';
import PeopleGroupIcon from '../../../Components/Icons/PeopleGroupIcon';
import AccountProfileIcon from '../../../Components/Icons/AccountProfileIcon';
import React, { useState } from 'react';
import UpdateDeviceModal from '../UpdateAbTestModals/UpdateDeviceModal';
import UpdateDateModal from '../UpdateAbTestModals/UpdateTestDateModal';
import AbTestIcon from '../../../Components/Icons/AbTestIcon';
import EearthIcon from '../../../Components/Icons/EearthIcon';
import FolderIcon from '../../../Components/Icons/FolderIcon';

const TestInfoModalDetails = ({ abTest, editable }) => {
  const [showModal, setShowModal] = useState('');
  const handleOpenModal = (modalType) => {
    setShowModal(modalType);
  };

  const handleCloseModal = () => {
    setShowModal('');
  };

  let tmp = [
    { icon: <CardIcon />, title: 'Status', value: abTest?.status },
    abTest?.store && { icon: <CardIcon />, title: 'Store', value: abTest?.store },
    abTest?.network && { icon: <EearthIcon />, title: 'Network', value: abTest?.network },
    abTest?.campaigns &&
      abTest?.campaigns !== '[]' && {
        icon: <FolderIcon />,
        title: 'Campaigns',
        value: JSON.parse(abTest?.campaigns).join('; '),
      },
    {
      icon: <PeopleGroupIcon />,
      title: 'Cohorts',
      value: (abTest?.cohort * 100).toFixed(0) + '%',
    },
    {
      icon: <CalendarIcon />,
      title: 'Start Date',
      value: moment.utc(abTest?.startTs).format('ll'),
    },
    {
      icon: <CalendarIcon />,
      title: 'End Date',
      value: moment.utc(abTest?.endTs).format('ll'),
    },
    editable && {
      value: (
        <div className="btn btn-success btn-sm" onClick={() => handleOpenModal('date')}>
          Update Date
        </div>
      ),
    },
    abTest?.build && { icon: <SettingsIcon />, title: 'Build #', value: abTest?.build },
    abTest?.deviceId && {
      icon: <MarketInsightIcon color="#4D6F9D" />,
      title: 'Device ID',
      value: abTest?.deviceId,
    },
    editable && {
      value: (
        <div className="btn btn-success btn-sm" onClick={() => handleOpenModal('device')}>
          Update Device ID
        </div>
      ),
    },
    abTest?.country && {
      icon: <EarthIcon />,
      title: 'Country',
      value: abTest?.country,
    },
    abTest.updatedBy && {
      icon: <AccountProfileIcon size={24} color="#4D6F9D" />,
      title: 'Updated By',
      value: abTest.updatedBy,
    },
  ].filter(Boolean);
  return (
    <div className="bg-white h-100 rounded px-3 py-2" style={{ maxWidth: 250 }}>
      {showModal === 'date' && <UpdateDateModal show abTest={abTest} onClose={handleCloseModal} />}
      {showModal === 'device' && <UpdateDeviceModal show abTest={abTest} onClose={handleCloseModal} />}
      {abTest.id && (
        <HeaderInfoCard classname="mb-1" title="Test ID" icon={<AbTestIcon color="#4D6F9D" />}>
          <div className="fs-8 overflow-hidden overflow-hidden text-truncate">{abTest.id}</div>
        </HeaderInfoCard>
      )}
      {tmp.map((el, index) => (
        <div key={index} className=" mw-100">
          <HeaderInfoCard classname="mb-1" title={el.title} icon={el.icon}>
            <div className="overflow-hidden text-truncate">{el.value}</div>
          </HeaderInfoCard>
        </div>
      ))}
    </div>
  );
};

export default TestInfoModalDetails;
