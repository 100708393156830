import React, { useMemo } from 'react';
import Loader from '../../Components/Loader';
import TnTable from '../../Components/Table/TnTable';
import UpdateAccountModal from './CreateAccount/UpdateAccountModal';
import { OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import moment from 'moment/moment';
import DeleteAccountModal from './CreateAccount/DeleteAccountModal';
import MenuDropdown from '../../Components/MenuDropdown';

const UserTable = ({ perPage = 20, page = 1, data = [], filters = [], refetchUsers }) => {
  const filteredUsers = useMemo(() => {
    if (data.length > 0) {
      let filteredData = data.filter((user) => {
        let valid = true;
        filters.forEach((filter) => {
          if (filter.value) {
            switch (filter.name) {
              case 'search':
                const searchValue = filter.value.toLowerCase();
                valid =
                  valid &&
                  (user.email.toLowerCase().includes(searchValue) ||
                    user.first_name.toLowerCase().includes(searchValue) ||
                    user.last_name.toLowerCase().includes(searchValue));
                break;
              case 'status':
                valid = valid && Number(filter.value) === Number(user.active);
                break;
              case 'company':
                valid = valid && filter.value === user.company;
                break;
              case 'role':
                valid = valid && user.role.toUpperCase() === filter.value.toUpperCase();
                break;
              default:
                break;
            }
          }
        });
        return valid;
      });

      return filteredData.map((user) => ({
        ...user,
        active: (
          <div className="d-flex">
            <span className={`${Number(user.active) === 1 ? 'bg-success' : 'bg-danger'} rounded-circle p-1`} />
          </div>
        ),
        created_at: moment(user.created_at).format('ll'),
        actions: <Stack direction="horizontal" gap="3">
          <UpdateAccountModal data={user} />
          <MenuDropdown title="More Actions" options={[
            <DeleteAccountModal data={user} onComplete={refetchUsers} />,
          ]} />
        </Stack>,
        studio_ids: (
          <div className="d-flex align-items-center">
            {Object.keys(user.studios).length > 1 ? (
              <OverlayTrigger overlay={<Tooltip>{Object.values(user.studios).join(', ')}</Tooltip>} placement="top">
                <div className="text-white fs-7 bg-info py-1 px-2 m-1 rounded cursor-pointer">
                  {Object.keys(user.studios).length}
                </div>
              </OverlayTrigger>
            ) : (
              <div>{Object.values(user.studios)}</div>
            )}
          </div>
        ),
      }));
    } else {
      return [];
    }
  }, [data, filters]);

  const header = [
    { key: 'active', label: '' },
    { key: 'email', label: 'E-mail', sortable: true },
    { key: 'first_name', label: 'First Name', sortable: true },
    { key: 'last_name', label: 'Last Name', sortable: true },
    { key: 'studio_ids', label: 'Studios' },
    { key: 'role', label: 'Role', sortable: true },
    { key: 'api_token', label: 'Api Token' },
    { key: 'apis_include', label: 'Apis Include' },
    { key: 'apis_exclude', label: 'Apis Exclude' },
    { key: 'created_at', label: 'Created At', sortable: true },
    { key: 'actions', label: '' },
  ];

  if (!data || data.length === 0) {
    return (
      <div className="d-flex justify-content-center">
        <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
      </div>
    );
  }

  return (
    <div className="col-12">
      {filteredUsers.length ? (
        <div className="table-responsive">
          <TnTable data={filteredUsers} page={page} perPage={perPage} header={header} />
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <span className="text-dark me-2">No results found.</span>
        </div>
      )}
    </div>
  );
};

export default UserTable;
