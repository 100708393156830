import React, { useMemo, useState } from 'react';
import { Button, Col, Form, FormControl, Row } from 'react-bootstrap';
import SearchableDropdown from '../../../../Components/SearchableDropdown';
import { abTestCountries } from '../../../../constants/abTestCountries';
import Params from '../_Components/Params';
import cohortParamsSchema from '../_validationSchemas/cohortParamsSchema';
import { formatJoiErrorsArray } from '../../../../utils/validation';
import { useGetGamesQuery } from '../../../../data/api/studioApi';
import { useSelector } from 'react-redux';
import { getGameById, getGamesByBundleId } from '../../../../data/slices/studio';
import ConfigList from './ConfigList';
import { Restricted } from '../../../../Components/permissions/UserPermissions';
import { useGetGameNetworksQuery, useGetGameCampaignsQuery } from '../../../../data/api/abTestsApi';
import { capitalizeFirstLetter } from '../../../../utils/converters';
import Loader from '../../../../Components/Loader';

function ConfigForm({ data, setData, errors, setGrewUp, grewUp }) {
  useGetGamesQuery();
  const foundGame = useSelector(getGameById(data.id));
  let gameBundleId = useMemo(() => data.bundleId, [data.bundleId]);

  const foundGames = useSelector(getGamesByBundleId(gameBundleId));
  const [errorsLocal, setErrors] = useState(errors);

  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const { data: foundNetworks, isFetching: isFetchingNetworks } = useGetGameNetworksQuery(foundGame.bundleId);
  const { data: foundCampaigns, isFetching: isFetchingCampaigns } = useGetGameCampaignsQuery({
    network: data.network,
    bundleId: foundGame.bundleId,
  });

  const handleConfigNameChange = (text) => {
    if (text.match(/([a-zA-Z0-9_-])$/g) || text === '') {
      setData({
        ...data,
        name: text.split(' ').join(''),
      });
    } else {
      return null;
    }
  };

  const handleConfigParamTextChange = (text, field, index) => {
    let newArray = [...data.params];
    newArray[index][field] = text;
    setData({
      ...data,
      params: newArray,
    });
  };

  const handleConfigAddParam = () => {
    const { error } = cohortParamsSchema.validate(data.params);
    if (error) {
      setErrors(formatJoiErrorsArray(error.details));
      return false;
    }
    const newArray = [...data.params, { name: '', value: '' }];
    setData({
      ...data,
      params: newArray,
    });
  };

  const handleConfigDeleteParam = (index) => {
    let newArray = [...data.params];
    if (newArray.length > 1) {
      newArray.splice(index, 1);
      setData({
        ...data,
        params: newArray,
      });
    }
  };

  const handleOsChange = (e) => {
    let os = e.target.value;
    setData({ os });
    if (os === 'android' && data.store.includes('apple')) {
      setData({ store: data.store.filter((s) => s !== 'apple') });
    } else if (os === 'ios') {
      setData({ store: data.store.includes('apple') ? ['apple'] : [] });
    }
  };

  const errorsS = Object.keys(errors).length ? errors : errorsLocal;

  return (
    <Form noValidate autoComplete="off">
      <Row>
        <Col xs={grewUp ? 6 : 12}>
          <Row className={'g-2'}>
            <Form.Group className="position-relative" as={Col} xs={12} controlId="cohortName">
              <Form.Label className="fs-7">
                Config Name <span className="text-danger fs-7">*</span>
              </Form.Label>
              <FormControl
                type="name"
                placeholder="Config Name"
                value={data.name}
                className="textInput"
                isInvalid={errors.name}
                onChange={(e) => handleConfigNameChange(e.target.value)}
                name="name"
              />

              <Form.Control.Feedback tooltip type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={6} className="position-relative  " controlId="targetOs">
              <Form.Label className="fs-7">
                Os <span className="text-danger fs-7">*</span>
              </Form.Label>
              <Form.Select
                aria-label="OS"
                value={data.os}
                onChange={handleOsChange}
                isInvalid={errors.os}
                disabled={!foundGame?.multipleOs}
              >
                <option value={''}>iOS / Android</option>
                <option value={'ios'}>iOS</option>
                <option value={'android'}>Android</option>
              </Form.Select>
              <Form.Control.Feedback tooltip type="invalid">
                {errors.os}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={6} className="pposition-relative " controlId="buildVersion">
              <Form.Label className="fs-7 ">Minimum Build Version</Form.Label>
              <FormControl
                type="text"
                value={data.build}
                isInvalid={errors.build}
                placeholder="Type Build version"
                onChange={(e) => setData({ build: e.target.value })}
                name="build"
              />
              <Form.Control.Feedback tooltip type="invalid">
                {errors.build}
              </Form.Control.Feedback>
            </Form.Group>

            {foundGames?.length > 1 && (
              <Form.Group className="position-relative" as={Col} xs={12} controlId="store">
                <Form.Label className="fs-7">Store</Form.Label>
                <SearchableDropdown
                  placeholder={'Select specific store or leave empty'}
                  isInvalid={errors.store}
                  writeSelected
                  parClass={errors.store && 'is-invalid'}
                  multiple
                  disableSearch
                  collapseGroup
                  onSelect={(data) => setData({ store: data })}
                  selected={data.store}
                  options={[
                    data.os !== 'android' && { value: 'apple', label: 'Apple' },
                    data.os !== 'ios' && { value: 'google', label: 'Google' },
                    data.os !== 'ios' && { value: 'amazon', label: 'Amazon' },
                  ].filter(Boolean)}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.store}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {!!foundNetworks?.length && !isFetchingNetworks && (
              <Form.Group className="position-relative" as={Col} xs={12} controlId="networks">
                <Form.Label className="fs-7">Source networks</Form.Label>
                <SearchableDropdown
                  multiple
                  onSelect={setSelectedNetworks}
                  selected={selectedNetworks}
                  options={foundNetworks.map((el) => ({ label: capitalizeFirstLetter(el), value: el }))}
                  onBatchChange={(data) => {
                    setData({ network: data.join(','), campaigns: [] });
                  }}
                  placeholder={'All Networks'}
                />
              </Form.Group>
            )}
            {!isFetchingNetworks && (
              <>
                {isFetchingCampaigns && (
                  <div className="d-flex justify-content-center p-3">
                    <Loader parentStyle="loader" size={24} color={'#3F96C7'} />
                  </div>
                )}
                {!!foundCampaigns?.length && !isFetchingCampaigns && (
                  <Form.Group className="position-relative" as={Col} xs={12} controlId="campaigns">
                    <Form.Label className="fs-7">Campaigns</Form.Label>
                    <SearchableDropdown
                      multiple
                      onSelect={(data) => setData({ campaigns: data })}
                      selected={data.campaigns}
                      options={foundCampaigns.map((el) => ({ label: el, value: el }))}
                      placeholder={'All Campaigns'}
                    />
                  </Form.Group>
                )}
              </>
            )}

            <Form.Group className="position-relative " as={Col} xs={6} controlId="deviceId">
              <Form.Label className="fs-7 ">
                Device Id <span className="text-muted fs-7"> (testing on one device)</span>
              </Form.Label>
              <FormControl
                type="text"
                placeholder="Type Device Id"
                value={data.deviceId}
                onChange={(e) => setData({ deviceId: e.target.value })}
                name="device-id"
              />
              <Form.Control.Feedback tooltip type="invalid">
                {errors.deviceId}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="position-relative " as={Col} xs={6} controlId="country">
              <Form.Label className="fs-7 ">Country</Form.Label>
              <SearchableDropdown
                multiple
                collapseGroup
                onSelect={(data) => setData({ country: data })}
                selected={data.country}
                options={abTestCountries}
                disableSearch
              />
            </Form.Group>

            <div className="col-12 ">
              <div className="p-2 border rounded">
                <Params
                  errors={errorsS}
                  paramsArray={data.params}
                  handleParamTextChange={handleConfigParamTextChange}
                  handleAddParam={handleConfigAddParam}
                  handleDeleteParam={handleConfigDeleteParam}
                />
              </div>
            </div>
            <Restricted permission="show.running.configs">
              <div className="text-end">
                <Button variant="outline-secondary" size={'sm'} onClick={() => setGrewUp(!grewUp)}>
                  {!grewUp ? 'Show ' : 'Hide '} running configs
                </Button>
              </div>
            </Restricted>
          </Row>
        </Col>
        {grewUp && (
          <Col xs={6}>
            <ConfigList data={data} />
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default ConfigForm;
