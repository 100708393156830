import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useUpdateFacebookAppIDMutation, useUpdateDraftGameFacebookAppIDMutation } from '../../../../../data/api/studioApi';
import Loader from '../../../../../Components/Loader';
import { confirmAlert } from '../../../../../Components/ConfirmModal';

const FbAppIDModal = ({ data }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [fbAppId, setFbAppId] = useState('');
  const [updateFacebookAppID, { isLoading: isUpdatingFbAppID }] = useUpdateFacebookAppIDMutation();
  const [updateDraftGameFacebookAppID, { isLoading: isUpdatingDraftGameFbAppID }] = useUpdateDraftGameFacebookAppIDMutation();

  const handleCreateFbApp = (e) => {
    e.stopPropagation();
    if (!fbAppId) {
      confirmAlert({
        variant: 'danger',
        title: 'Required fields are empty',
        confirmBtn: false,
        cancelText: 'Ok',
      }).catch(() => {});
      return;
    }
    const data = {
      fb_app_id: fbAppId,
      studio_id: data.studioId,
      bundle_id: data.bundleId,
      store: data.store === 'amazon' ? ['amazon'] : [],
    };
    (data?.isDraft ? updateDraftGameFacebookAppID(data) : updateFacebookAppID(data))
      .unwrap()
      .then(() => {
        setShowCreateModal(false);
        confirmAlert({
          variant: 'success',
          title: 'Facebook App ID was successfully updated',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      })
      .catch((err) => {
        confirmAlert({
          variant: 'danger',
          title: err?.data?.message || 'Failed to update Facebook App ID',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      });
  };

  const handleCreateButtonClick = () => {
    setShowCreateModal(true);
  };

  return (
    <>
      {!data?.fbAppId && (
        <Button onClick={handleCreateButtonClick} size="sm" disabled={isUpdatingFbAppID || isUpdatingDraftGameFbAppID}>
          {(isUpdatingFbAppID || isUpdatingDraftGameFbAppID) ? <Loader parentStyle="me-2" size={20} color="#3F96C7" /> : 'Create Facebook App ID'}
        </Button>
      )}

      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Facebook App ID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter a new Facebook App ID for this game:</p>
          <Form>
            <Form.Group controlId="facebookAppId">
              <Form.Label>Facebook App ID</Form.Label>
              <Form.Control
                type="text"
                value={fbAppId}
                onChange={(e) => setFbAppId(e.target.value)}
                placeholder="Enter Facebook App ID"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateFbApp} disabled={!fbAppId || isUpdatingFbAppID || isUpdatingDraftGameFbAppID}>
            {(isUpdatingFbAppID || isUpdatingDraftGameFbAppID) ? 'Creating...' : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FbAppIDModal;
