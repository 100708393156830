import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
import { snakeToCamelRec } from '../../utils/converters';
const baseUrl = process.env.REACT_APP_USER_API;

export const userApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['users'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/login',
        method: 'POST',
        body: credentials,
      }),
      transformResponse: snakeToCamelRec,
    }),
    register: builder.mutation({
      query: (args) => ({
        url: '/',
        method: 'POST',
        body: args,
      }),
    }),
    createUserStudio: builder.mutation({
      query: (args) => ({
        url: '/studio',
        method: 'POST',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
    }),
    refreshUserData: builder.mutation({
      query: (args) => ({
        url: '/refresh-auth',
        method: 'POST',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
    }),
    requestJoinStudio: builder.mutation({
      query: (args) => ({
        url: '/studio/request',
        method: 'POST',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: (email) => ({
        url: '/forgot_password',
        method: 'POST',
        body: { email },
      }),
    }),
    createUser: builder.mutation({
      query: (args) => ({
        url: '/create',
        method: 'POST',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
      invalidatesTags: (result) => {
        return result ? ['users'] : [];
      },
    }),
    updateUserProfile: builder.mutation({
      query: (args) => ({
        url: '/',
        method: 'put',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),

      invalidatesTags: (result) => {
        return result ? ['users'] : [];
      },
    }),
    deleteUser: builder.mutation({
      query: (args) => {
        const url = `/${args.id}`;
        return {
          url,
          method: 'delete',
          headers: {
            Authorization: hmac256(baseUrl + url),
          },
        }
      },
      invalidatesTags: (result) => {
        return result ? ['draftGames'] : [];
      },
    }),
    updateUser: builder.mutation({
      query: (args) => ({
        url: `/${args.id}`,
        method: 'put',
        body: args,
        headers: {
          Authorization: hmac256(args),
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData('listUsers', undefined, (draft) => {
              const index = draft.findIndex((el) => el.id === args.id);
              if (index > -1) {
                draft[index] = updatedData;
              }
            }),
          );
        } catch {}
      },
    }),
    listUsers: builder.query({
      keepUnusedDataFor: 600, //keep data for 10 min
      query: (studioId = '') => ({
        url: `/${studioId}`,
        headers: { Authorization: hmac256(baseUrl + `/${studioId}`) },
      }),
      providesTags: ['users'],
    }),
  }),
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useRegisterMutation,
  useCreateUserStudioMutation,
  useRefreshUserDataMutation,
  useRequestJoinStudioMutation,
  useUpdateUserProfileMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useListUsersQuery,
  useCreateUserMutation,
} = userApi;
