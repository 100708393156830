import { useSelector } from 'react-redux';
import { userSelector } from '../../data/slices/user';
import { ListGroup, Nav, Navbar } from 'react-bootstrap';
import { generatePath, matchPath, NavLink, useHistory, useLocation } from 'react-router-dom';
import MarketInsightIcon from '../../Components/Icons/MarketInsightIcon';
import GameIcon from '../../Components/Icons/GamesIcon';
import AbTestIcon from '../../Components/Icons/AbTestIcon';
import Document from '../../Components/Icons/Document';
import TapNationLogo from '../../assets/logoTapNation/Plan de travail 47.png';

import {
  R_AB_TEST,
  R_AB_TEST_METRICS,
  R_AB_TESTS,
  R_ACCOUNT,
  R_AI_TOOLS,
  R_ANALYTICS_ACQUISITION,
  R_ANALYTICS_MONETIZATION,
  R_GAME,
  R_GAMES,
  R_HOME,
  R_LOGIN,
  R_MARKET_INSIGHT,
  R_OVERVIEW_ANALYTICS,
  R_SDK,
  R_TOP_APPS,
  R_UM_ACCOUNTS,
  R_UM_STUDIO_REQUEST,
  R_UM_STUDIOS,
} from '../../constants/routes';
import AiIcon from '../../Components/Icons/AiIcon';
import AnalyticsIcon from '../../Components/Icons/AnalyticsIcon';
import { useEffect, useState } from 'react';
import ArrowDown from '../../Components/Icons/ArrowDown';
import UserAccount from './UserAccount';
import SettingsIcon from '../../Components/Icons/SettingsIcon';
import AccountProfileIcon from '../../Components/Icons/AccountProfileIcon';
import ColorModes from './ColorModes';
import { Restricted } from '../../Components/permissions/UserPermissions';
import SelectStudio from '../../Modules/SelectStudio';

function Sidebar() {
  const [collapseClass, setCollapseClass] = useState('');

  useEffect(() => {
    const tmp = localStorage.getItem('collapseClass');
    if (tmp !== null) {
      setCollapseClass(tmp);
    }
  }, []);

  const handleButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let tmp = collapseClass ? '' : 'collapsed';
    setCollapseClass(collapseClass ? 'closing' : 'opening');
    setTimeout(() => {
      setCollapseClass(tmp);
    }, 1000);
    localStorage.setItem('collapseClass', tmp);
  };

  const user = useSelector(userSelector);
  const location = useLocation();
  const history = useHistory();
  return (
    <>
      <Navbar
        collapseOnSelect
        className={`sidenav d-print-none border-end ${collapseClass}`}
        bg="bg-transparent"
        expand="md"
      >
        <div
          className="position-absolute h-100 start-100 top-50 translate-middle collapse-btn-wrapper"
          onClick={handleButtonClick}
        >
          <div className="navbar-button">
            <ArrowDown rotate={collapseClass === 'collapsed' ? 'left' : 'right'} size={10} color={'#ffffff'} />
          </div>
        </div>
        <Navbar.Brand as={NavLink} to={R_HOME}>
          <div className="brand">
            <img src={TapNationLogo} width={155} alt="logo" />
          </div>
        </Navbar.Brand>
        <div className="d-flex">
          <div className="nav-link-text navbar-toggler border-0 p-1">
            <Restricted permission="select.studio">
              <SelectStudio />
            </Restricted>
          </div>
          <Navbar.Toggle bsPrefix="navbar-toggler border-0" aria-controls="main-navbar-nav">
            <span className={`navTrigger me-2`}>
              <i />
              <i />
              <i />
            </span>
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="main-navbar-nav">
          <Nav className="flex-sm-column collapsed h-100">
            <NavLink to={R_SDK}>
              <Nav.Link as="div" href={R_SDK}>
                <Document size={28} />
                <span className="nav-link-text">SDK Integration</span>
              </Nav.Link>
            </NavLink>
            <NavLink
              isActive={(match, location) =>
                match?.isExact ||
                matchPath(location.pathname, { path: R_GAMES }) ||
                matchPath(location.pathname, { path: R_GAME })
              }
              to={generatePath(R_GAMES, { filter: null })}
            >
              <Nav.Link as="div" href={R_GAMES}>
                <GameIcon size={28} />
                <span className="nav-link-text">Games</span>
              </Nav.Link>
            </NavLink>
            <NavLink
              to={R_AB_TESTS}
              isActive={(match, location) => {
                return (
                  match?.isExact ||
                  matchPath(location.pathname, { path: R_AB_TEST_METRICS }) ||
                  matchPath(location.pathname, { path: R_AB_TEST })
                );
              }}
            >
              <Nav.Link as="div" href={R_AB_TESTS}>
                <AbTestIcon size={28} />
                <span className="nav-link-text">A/B Tests</span>
              </Nav.Link>
            </NavLink>

            <Nav.Item
              onClick={() => history.push(R_TOP_APPS)}
              className={
                matchPath(location.pathname, { path: R_TOP_APPS }) ||
                matchPath(location.pathname, { path: R_MARKET_INSIGHT })
                  ? 'active position-relative'
                  : 'position-relative'
              }
            >
              <div className="position-relative">
                <MarketInsightIcon size={28} />
              </div>
              <span className="nav-link-text p-0 cursor-pointer">
                <ListGroup variant="flush">
                  <ListGroup.Item className="header">Marketing</ListGroup.Item>
                  <NavLink
                    to={R_TOP_APPS}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <ListGroup.Item as="div" href={R_TOP_APPS}>
                      - Top Chart
                    </ListGroup.Item>
                  </NavLink>
                  <NavLink to={R_MARKET_INSIGHT} onClick={(e) => e.stopPropagation()}>
                    <ListGroup.Item as="div" href={R_MARKET_INSIGHT} className="text-truncate">
                      - Market Insights
                    </ListGroup.Item>
                  </NavLink>
                </ListGroup>
              </span>
            </Nav.Item>
            <Nav.Item
              onClick={() => history.push(R_OVERVIEW_ANALYTICS)}
              className={
                matchPath(location.pathname, { path: R_ANALYTICS_ACQUISITION }) ||
                matchPath(location.pathname, { path: R_OVERVIEW_ANALYTICS }) ||
                matchPath(location.pathname, { path: R_ANALYTICS_MONETIZATION })
                  ? 'active position-relative'
                  : 'position-relative'
              }
            >
              <div className="position-relative">
                <span className="position-absolute top-0 start-100 translate-middle border p-1 bg-warning rounded-circle">
                  <span className="visually-hidden">New Feature</span>
                </span>
                <AnalyticsIcon size={28} />
              </div>
              <span className="nav-link-text p-0 cursor-pointer">
                <ListGroup variant="flush">
                  <ListGroup.Item className="header">Reporting</ListGroup.Item>
                  <NavLink to={R_OVERVIEW_ANALYTICS} onClick={(e) => e.stopPropagation()}>
                    <ListGroup.Item as="div" href={R_OVERVIEW_ANALYTICS}>
                      - Overview
                    </ListGroup.Item>
                  </NavLink>
                  <NavLink
                    to={R_ANALYTICS_MONETIZATION}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <ListGroup.Item as="div" href={R_ANALYTICS_MONETIZATION}>
                      - Monetization
                    </ListGroup.Item>
                  </NavLink>
                  <NavLink to={R_ANALYTICS_ACQUISITION} onClick={(e) => e.stopPropagation()}>
                    <ListGroup.Item as="div" href={R_ANALYTICS_ACQUISITION}>
                      - User Acquisition
                    </ListGroup.Item>
                  </NavLink>

                  {/*<NavLink to={R_ANALYTICS_ENGAGEMENT} onClick={(e) => e.stopPropagation()}>*/}
                  {/*  <ListGroup.Item action as="div">*/}
                  {/*    Engagement*/}
                  {/*  </ListGroup.Item>*/}
                  {/*</NavLink>*/}
                </ListGroup>
              </span>
            </Nav.Item>
            <>
              <Restricted permission="aiTools">
                <NavLink to={R_AI_TOOLS}>
                  <Nav.Link as="div" href={R_AI_TOOLS}>
                    <AiIcon size={28} />
                    <span className="nav-link-text">AI tools</span>
                  </Nav.Link>
                </NavLink>
              </Restricted>
              {/*<Nav.Item
                  onClick={() => history.push(R_GOOGLE_ADS)}
                  className={
                    matchPath(location.pathname, { path: R_GOOGLE_ADS }) ||
                    matchPath(location.pathname, { path: R_FACEBOOK_ADS })
                      ? 'active position-relative'
                      : 'position-relative'
                  }
                >
                  <div className="">
                    <AdIcon size={28} />
                  </div>
                  <span className="nav-link-text p-0 cursor-pointer">
                    <ListGroup variant="flush">
                      <ListGroup.Item className="header">Ads</ListGroup.Item>
                      <NavLink
                        to={R_GOOGLE_ADS}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <ListGroup.Item as="div" href={R_GOOGLE_ADS}>
                          - Google Ads
                        </ListGroup.Item>
                      </NavLink>
                      <NavLink to={R_FACEBOOK_ADS} onClick={(e) => e.stopPropagation()}>
                        <ListGroup.Item as="div" href={R_FACEBOOK_ADS}>
                          - Facebook Ads
                        </ListGroup.Item>
                      </NavLink>
                    </ListGroup>
                  </span>
                </Nav.Item>*/}
              <Restricted permission="manage.account">
                <Nav.Item
                  onClick={() => history.push(R_UM_ACCOUNTS)}
                  className={
                    matchPath(location.pathname, { path: R_UM_ACCOUNTS }) ||
                    matchPath(location.pathname, { path: R_UM_STUDIOS })
                      ? 'active position-relative'
                      : 'position-relative'
                  }
                >
                  <div className="position-relative">
                    <SettingsIcon size={28} />
                  </div>
                  <span className="nav-link-text p-0 cursor-pointer">
                    <ListGroup variant="flush">
                      <ListGroup.Item className="header">Manage Accounts</ListGroup.Item>
                      <NavLink
                        to={R_UM_ACCOUNTS}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <ListGroup.Item as="div" href={R_UM_ACCOUNTS}>
                          - Accounts
                        </ListGroup.Item>
                      </NavLink>
                      <Restricted permission="manage.studio">
                        <NavLink to={R_UM_STUDIOS} onClick={(e) => e.stopPropagation()}>
                          <ListGroup.Item as="div" href={R_UM_STUDIOS} className="text-truncate">
                            - Studios
                          </ListGroup.Item>
                        </NavLink>
                      </Restricted>
                      <Restricted permission="manage.studio">
                        <NavLink to={R_UM_STUDIO_REQUEST} onClick={(e) => e.stopPropagation()}>
                          <ListGroup.Item as="div" href={R_UM_STUDIO_REQUEST} className="text-truncate">
                            - Join Studio Requests
                          </ListGroup.Item>
                        </NavLink>
                      </Restricted>
                    </ListGroup>
                  </span>
                </Nav.Item>
              </Restricted>
              <Nav.Item
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className={
                  matchPath(location.pathname, { path: R_ACCOUNT })
                    ? 'active position-relative personal-info'
                    : 'position-relative personal-info'
                }
              >
                <div className="position-relative">
                  <AccountProfileIcon size={28} color="#666666" />
                </div>
                <span className="nav-link-text p-0 cursor-pointer">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="header">
                      {user.firstName} {user.lastName}
                    </ListGroup.Item>
                    <NavLink to={R_ACCOUNT}>
                      <ListGroup.Item as="div" href={R_ACCOUNT}>
                        - Edit Account
                      </ListGroup.Item>
                    </NavLink>
                    <NavLink to={R_LOGIN}>
                      <ListGroup.Item as="div" className={'text-danger '} href={R_LOGIN}>
                        - Logout
                      </ListGroup.Item>
                    </NavLink>
                    <div className="nav-link-text p-0">
                      <ColorModes />
                    </div>
                  </ListGroup>
                </span>
              </Nav.Item>
            </>
          </Nav>
        </Navbar.Collapse>
        <div className="w-100 mt-2 d-flex flex-column userInfo border-top">
          <div className="mx-2 my-1">
            <ColorModes />
          </div>
          <Restricted permission="select.studio">
            <div className="mx-2 my-1">
              <SelectStudio dropDirection={'up'} className="selectStudio" />
            </div>
          </Restricted>
          <div className="mx-2 my-1">
            <UserAccount />
          </div>
        </div>
      </Navbar>
    </>
  );
}

export default Sidebar;
