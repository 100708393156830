import React, { useState, useEffect } from 'react';
import Pagination from '../../Components/Pagination';
import { useListUsersQuery } from '../../data/api/userApi';
import { useQuery } from '../../utils/customHooks';
import { useListStudiosQuery } from '../../data/api/studioApi';
import { userRoles } from '../../constants/permissions';
import TnTableFiltersContainer from '../../Components/Table/TnTableFiltersContainer/TnTableFiltersContainer';
import UserTable from './userTable';

const UserList = () => {
  const { data: users, isFetching: isLoading, refetch: refetchUsers } = useListUsersQuery(undefined);
  const query = useQuery();
  const initialSearch = query.get('search') || '';
  const perPage = 20;
  const [page, setPage] = useState(1);
  const { data: studioList } = useListStudiosQuery(undefined, {});
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filters, setFilters] = useState([
    { name: 'search', type: 'search', value: initialSearch, placeholder: 'Search Users...' },
    {
      name: 'status',
      type: 'select',
      label: 'Status',
      options: [
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 0 },
      ],
      value: '',
    },
    { name: 'company', type: 'select', label: 'Companies', options: [], value: '' },
    {
      name: 'role',
      type: 'select',
      label: 'Roles',
      options: userRoles.map((item) => ({
        label: item.label,
        value: item.value,
      })),
      value: '',
    },
  ]);

  useEffect(() => {
    if (studioList) {
      setFilters((filters) =>
        filters.map((filter) => {
          if (filter.name === 'company') {
            const companies = studioList.map((item) => ({
              label: item.name,
              value: item.name,
            }));
            return { ...filter, options: companies };
          }
          return filter;
        }),
      );
    }
  }, [studioList]);

  useEffect(() => {
    if (users) {
      const filteredData = users.filter((user) => {
        let valid = true;
        filters.forEach((filter) => {
          if (filter.value) {
            switch (filter.name) {
              case 'search':
                const searchValue = filter.value?.toLowerCase();
                valid =
                  valid &&
                  (user.email?.toLowerCase().includes(searchValue) ||
                    user.firstName?.toLowerCase().includes(searchValue));
                break;
              case 'status':
                valid = valid && user.active === Number(filter.value);
                break;
              case 'company':
                valid = valid && user.company === filter.value;
                break;
              case 'role':
                valid = valid && user.role === filter.value;
                break;
              default:
                break;
            }
          }
        });
        return valid;
      });
      setFilteredUsers(filteredData);
    }
  }, [users, filters]);

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 d-flex flex-lg-row flex-column gap-1 align-items-lg-center">
          {isLoading ? null : (
            <div className="d-flex gap-1">
              <TnTableFiltersContainer
                filters={filters}
                setFilters={(s) => {
                  setFilters(s);
                  setPage(1);
                }}
              />
            </div>
          )}
          <div className="ms-auto d-flex align-items-center">
            <Pagination
              total={filteredUsers.length || 0}
              perPage={perPage}
              page={page}
              pushHistory={false}
              onChange={(currentPage) => {
                setPage(currentPage);
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <UserTable perPage={perPage} page={page} data={filteredUsers} filters={filters} refetchUsers={refetchUsers} />
      </div>
    </>
  );
};

export default UserList;
