import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import InfoIcon from '../../../Components/Icons/InfoIcon';
import CheckmarkIcon from '../../../Components/Icons/CheckmarkIcon';
import EyeSlashIcon from '../../../Components/Icons/EyeSlashIcon';
import EyeVisibleIcon from '../../../Components/Icons/EyeVisibleIcon';
import { useRegisterMutation } from '../../../data/api/userApi';
import singUpSchema from './singUpSchema';
import { formatJoiErrorsArray } from '../../../utils/validation';

function SignUpForm() {
  const [register, { isLoading }] = useRegisterMutation();
  const [state, setState] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    studioName: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleTextChange = (text, field) => {
    setState({ ...state, [field]: text });
    if (errors && errors[field]) {
      setErrors({ ...errors, [field]: '' });
    }
  };

  const handleCreateAccount = (e) => {
    e.preventDefault();

    const formatSignUpValidation = (details) => {
      let err = details.map((error) => {
        return {
          path: error.path,
          message: error.message,
        };
      });
      return formatJoiErrorsArray(err);
    };

    const { error } = singUpSchema.validate({ ...state, role: 'USER' }, { abortEarly: false });
    if (error) {
      return setErrors(formatSignUpValidation(error.details));
    }
    const post = {
      email: state.email,
      password: state.password,
      first_name: state.firstName,
      last_name: state.lastName,
      company: state.studioName,
    };

    register(post)
      .unwrap()
      .then((res) => {
        const stateData = {
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          studioName: '',
          success: 'Your account has been created!',
        };
        if (res?.existingStudioError) stateData.info = 'Studio name already exist, upon login, you will be asked to either create new studio name or request to join existing studio';
        setState(stateData);
        setErrors({});
      })
      .catch((e) => {
        if (e?.data?.details) {
          return setErrors(formatSignUpValidation(e.data.details));
        }
        setErrors({ error: e?.data?.message || e.message || 'Something went wrong' });
      });
  };
  return (
    <>
      <Form className="g-3" onSubmit={handleCreateAccount}>
        <Row>
          {errors?.error && (
            <div className="alert alert-danger mb-3 d-flex align-items-center fs-7" role="alert">
              <div className="me-3">
                <InfoIcon />
              </div>
              <div>
                <div>{errors.error}</div>
              </div>
            </div>
          )}
          {state.success && (
            <div className="alert alert-success mb-3 d-flex align-items-center fs-7" role="alert">
              <div className=" me-3">
                <CheckmarkIcon size={16} />
              </div>
              <div>{state.success}</div>
            </div>
          )}
          {state.info && (
            <div className="alert alert-info mb-3 d-flex align-items-center fs-7" role="alert">
              <div className=" me-3">
                <InfoIcon size={16} />
              </div>
              <div>{state.info}</div>
            </div>
          )}
          <Form.Group as={Col} sm={6} xs={12} controlId="firstname">
            <Form.Label className="fs-7 text-black fw-bold">First Name</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                size="lg"
                type="text"
                className="fs-6"
                aria-describedby="Type name"
                placeholder="First Name"
                value={state.firstName}
                onChange={(text) => handleTextChange(text.target.value, 'firstName')}
                isInvalid={!!errors.firstName}
              />
              <Form.Control.Feedback tooltip type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} sm={6} xs={12} controlId="lastname">
            <Form.Label className="fs-7 text-black fw-bold">Last Name</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                className="fs-6"
                size="lg"
                aria-describedby="Type last name"
                placeholder="Last Name"
                value={state.lastName}
                onChange={(text) => handleTextChange(text.target.value, 'lastName')}
                isInvalid={!!errors.lastName}
              />
              <Form.Control.Feedback tooltip type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} xs={12} controlId="studioid">
            <Form.Label className="fs-7 text-black fw-bold">Studio Name</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                className="fs-6"
                size="lg"
                aria-describedby="Studio Name"
                placeholder="Type studio name"
                value={state.studioName}
                onChange={(text) => handleTextChange(text.target.value, 'studioName')}
                isInvalid={!!errors.studioName}
              />
              <Form.Control.Feedback tooltip type="invalid">
                {errors.studioName}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2" as={Col} xs={12} controlId="emailadd">
            <Form.Label className="fs-7 text-black fw-bold">Email address</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="email"
                className="fs-6"
                size="lg"
                aria-describedby="Email"
                placeholder="Email"
                value={state.email}
                onChange={(text) => handleTextChange(text.target.value, 'email')}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback tooltip type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} xs={12} controlId="gameBundleId">
            <Form.Label className="fs-7 text-black fw-bold">Password</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                className="fs-6"
                size="lg"
                aria-describedby="Password"
                placeholder="Type password"
                value={state.password}
                onChange={(text) => handleTextChange(text.target.value, 'password')}
                isInvalid={!!errors.password}
              />
              <InputGroup.Text className="cursor-pointer">
                <div className="cursor-pointer d-flex align-items-center" onClick={toggleShowPassword}>
                  {showPassword ? <EyeSlashIcon color="#829ABA" /> : <EyeVisibleIcon color="#829ABA" />}
                </div>
              </InputGroup.Text>
              <Form.Control.Feedback tooltip type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Col xs={12}>
            <Button className="fs-6 w-100 mt-2" size="lg" type="submit" disabled={isLoading}>
              Sign Up
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default SignUpForm;
