import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SelectAbTestGame from '../_Components/SelectAbTestGame';
import PropTypes from 'prop-types';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import { confirmAlert } from '../../../../Components/ConfirmModal';
import moment from 'moment';
import { useCreateGameAbTestMutation, useEligibleToJsonMetricsQuery } from '../../../../data/api/abTestsApi';
import abTestSchema from '../_validationSchemas/abTestSchema';
import { useSetState } from '../../../../utils/customHooks';
import { formatJoiErrorsArray } from '../../../../utils/validation';
import Loader from '../../../../Components/Loader';
import '../createAbTest.scss';
import AbtestForm from './AbtestForm';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../../data/slices/studio';
import { useGetGamesQuery } from '../../../../data/api/studioApi';

function CreateTestModal({ onClose, gameId }) {
  const [createGameAbTest, { isLoading }] = useCreateGameAbTestMutation();
  const [step, setStep] = useState(gameId ? 2 : 1);
  const [errors, setErrors] = useState({});

  const initialData = {
    studioId: '',
    country: [],
    name: '',
    id: gameId || '',
    bundleId: '',
    build: '',
    device: '',
    os: 'all',
    store: [],
    network: '',
    campaigns: [],
    totalP: 10,
    startDate: moment().utc(false).add(1, 'day').startOf('day'),
    endDate: moment().utc(false).add(3, 'days').endOf('day'),
    cohorts: [],
  };
  const [state, setState] = useSetState(initialData);
  const {
    data: eligible,
    isFetching,
    errorEligible,
  } = useEligibleToJsonMetricsQuery(state.bundleId, {
    skip: !state.bundleId || step < 2,
  });
  useGetGamesQuery();
  const foundGame = useSelector(getGameById(state.id));

  useEffect(() => {
    if (foundGame && !foundGame?.multipleOs && foundGame?.os !== state?.os) setState({ os: foundGame?.os });
    if (foundGame && !state.bundleId) setState({ bundleId: foundGame?.bundleId });
  }, [foundGame]);
  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrors({});
    }
  }, [state]);

  const handleClose = () => {
    onClose();
  };
  const handleBack = () => {
    if (step === 1) {
      handleClose();
    } else {
      setStep(step - 1);
    }
  };
  const formatStep2Validation = (details) => {
    let error = details.map((err) => {
      if (err.path[0] === 'cohorts' && err.path[2] === 'params') {
        return { path: ['cohorts'], message: 'Cohorts validation failed, Please add at least one' };
      }
      return err;
    });

    return formatJoiErrorsArray(error);
  };
  const handleCreateAbtest = (state) => {
    let postObj = { ...state };
    const percent = (state.totalP * (100 / (state.cohorts.length + (eligible?.eligible ? 1 : 0)))) / 100;
    postObj.cohorts = postObj.cohorts.map((el) => ({
      ...el,
      cohort: percent,
    }));
    postObj.gen = 1;

    if (eligible?.eligible) {
      postObj.gen = 2;
      postObj.cohorts.unshift({
        name: 'BaseCohort',
        cohort: percent,
        params: [{ name: 'N/A', value: 'N/A' }],
      });
    }
    postObj.country = postObj.country.join(',');
    delete postObj.totalP;
    let startD = moment(state.startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let endD = moment(state.endDate).set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    postObj.startDate = startD.format('YYYY-MM-DD HH:mm:ss');
    postObj.endDate = endD.format('YYYY-MM-DD HH:mm:ss');
    postObj.studioId = foundGame?.studioId;

    createGameAbTest(postObj)
      .unwrap()
      .then(() => {
        confirmAlert({
          title: 'You have successfully created AB test',
          variant: 'success',
          confirmBtn: false,
          cancelText: 'Ok',
          // className: 'confirm-ab-test-alert',
        }).catch(() => {});
        handleClose();
      })
      .catch((e) => {
        if (e?.data?.details) {
          return setErrors(formatStep2Validation(e.data.details));
        } else setErrors({ error: e?.data?.message || e.message || 'Something went wrong' });
      });
  };
  const handleNext = () => {
    if (step === 1) {
      if (!state.bundleId) {
        return setErrors({ bundleId: 'Game is required' });
      }
    }
    if (step === 2) {
      let tmp = JSON.parse(JSON.stringify(state));
      tmp.cohorts = tmp.cohorts.map((value) => ({
        ...value,
        params: value.params.filter((el) => el.name !== '' && el.value !== ''),
      }));
      tmp.startDate = state.startDate.format('lll');
      tmp.endDate = state.endDate.format('lll');
      const { value, error } = abTestSchema.validate(tmp);

      if (error) {
        return setErrors(formatStep2Validation(error.details));
      }
      return handleCreateAbtest(value);
    }
    setStep(step + 1);
  };
  return (
    <>
      <Modal show={true} onHide={handleClose} scrollable={step === 1} backdrop="static" id="create-ab-test-modal">
        <Modal.Header closeButton>
          <Modal.Title>Create New A/B test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 300 }}>
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            <>
              {(errors?.error || errorEligible) && (
                <div className="alert alert-danger fs-7">
                  {errors?.error || errorEligible?.data?.message || 'Something went Wrong'}
                </div>
              )}
              <SelectAbTestGame
                errors={errors}
                gameId={state.id}
                selected={step === 2}
                onSelect={(game) => setState({ bundleId: game?.bundleId, id: game.id })}
              />
              {step === 2 && (
                <>
                  {eligible?.eligible === false && !isFetching && (
                    <div className="alert alert-warning mb-3 d-flex align-items-center fs-7" role="alert">
                      <div className="me-3">
                        <InfoIcon />
                      </div>
                      <div>
                        To collect test data, make sure your app embeds "FGAdjust 3.2.4" module or a later version
                      </div>
                    </div>
                  )}
                  <AbtestForm state={state} setState={setState} eligible={eligible?.eligible} errors={errors} />
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="text-muted fs-7">Step {step} of 2</div>
          <div className="ms-auto">
            <Button type="button" variant="secondary" className="ms-auto" onClick={handleBack}>
              {step === 2 ? 'Back' : 'Cancel'}
            </Button>
            <Button type="button" variant="primary" className="ms-3" onClick={handleNext} id="modal-next-button">
              {step === 2 ? 'Create' : 'Next'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateTestModal;

CreateTestModal.propTypes = {
  bundleId: PropTypes.string,
  onClose: PropTypes.func,
};
