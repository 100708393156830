import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useListClientsQuery } from '../../../../../data/api/fbAdsApi';
import SearchableDropdown from '../../../../../Components/SearchableDropdown';
import Loader from '../../../../../Components/Loader';

function FBCustomerClientList({ value, onSelect }) {
  const { data: clients, isLoading } = useListClientsQuery();

  const handleSelectChange = (selectedIds) => {
    if (!onSelect) return;
    const selectedClient = clients.find((client) => client.id === selectedIds[0]);
    if (selectedClient) {
      onSelect({ id: selectedClient.id, name: selectedClient.name, currency: selectedClient.currency });
    }
  };

  const filteredAdAccounts = useMemo(() => {
    return clients
      ?.filter((client) => client.name.toLowerCase().includes('ua test'))
      .map((client) => ({ label: client.name, value: client.id }))
      .sort((x, y) => x.label.localeCompare(y.label));
  }, [clients]);

  return (
    <Form.Group controlId="clientSelect" className="d-flex align-items-center gap-2">
      {isLoading ? (
        <Loader parentStyle="loader" size={23} color={'#3F96C7'} />
      ) : (
        <SearchableDropdown
          writeSelected={true}
          allowClear={false}
          selected={[value]}
          onSelect={handleSelectChange}
          options={filteredAdAccounts}
          placeholder={'Select Client Account'}
        />
      )}
    </Form.Group>
  );
}

export default FBCustomerClientList;
