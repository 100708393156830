import { Button, Badge, Form } from 'react-bootstrap';
import GameInfoCard from '../../../../Components/GameInfoCard';

const MetricsHeader = ({ data, os, changeOs }) => {
  return (
    <div className="row g-3 g-md-4 mb-3">
      <div className="col-sm-12 col-md-6 col-xl-4">{data && <GameInfoCard os={os} id={data.id} />}</div>
      <div className="col-sm-12 col-md-6 col-xl-8 d-flex">
        {data.multipleOs && (
          <div className="d-flex ms-auto mt-auto gap-1">
            <Button
              size="sm"
              onClick={() => changeOs('android')}
              variant={os === 'android' ? 'primary' : 'outline-secondary'}
            >
              Android
            </Button>
            <Button size="sm" onClick={() => changeOs('ios')} variant={os === 'ios' ? 'primary' : 'outline-secondary'}>
              IOS
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MetricsHeader;
