import { useEffect, useState } from 'react';
import InfosView from '../CreateGame/Steps/InfosView';
import SDKView from '../CreateGame/Steps/SDKView';
import { Button, Modal } from 'react-bootstrap';
import { useSetState } from '../../../utils/customHooks';
import { useSelector } from 'react-redux';
import {
  useDeleteDraftGameMutation,
  useGetDraftGamesQuery,
  useUpdateDraftGameMutation,
} from '../../../data/api/studioApi';
import Loader from '../../../Components/Loader';
import infosViewSchema from '../CreateGame/Steps/validationSchemas/infosViewSchema';
import { camelToSnakeCaseObj } from '../../../utils/converters';
import { formatJoiErrorsArray } from '../../../utils/validation';
import { studioSelectedSelector, userSelector } from '../../../data/slices/user';
import DeleteIcon from '../../../Components/Icons/DeleteIcon';
import { confirmAlert } from '../../../Components/ConfirmModal';
import EditIcon from '../../../Components/Icons/EditIcon';
import { useDeleteCloudVideoMutation } from '../../../data/api/googleCloudApi';
import { Restricted, useUserPermission } from '../../../Components/permissions/UserPermissions';

function UpdateDraftGameModal({ id, onDelete }) {
  const [deleteCloud] = useDeleteCloudVideoMutation();
  const { data: draftGames } = useGetDraftGamesQuery();
  const [game, setGame] = useState(null);
  useEffect(() => {
    if (draftGames) {
      let game = draftGames.find((el) => el.id === Number(id));
      setGame(game);
    }
  }, [draftGames]);

  const [updateDraftGame] = useUpdateDraftGameMutation();
  const [deleteDraftGame] = useDeleteDraftGameMutation();
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const user = useSelector(userSelector);
  const selectStudioPermission = useUserPermission('select.studio');
  const studioSelected = useSelector(studioSelectedSelector);

  const infosDefaultVal = {
    studioId: selectStudioPermission && studioSelected?.studioId ? studioSelected?.studioId : user?.studioId,
    appName: '',
    bundleId: '',
    store: '',
    appId: '',
    bizdevEmail: '',
    category: '',
    tags: [],
    todoList: [],
    advertisingSdk: '',
  };
  const [infos, setInfos] = useSetState(infosDefaultVal);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrors({});
    }
  }, [infos]);

  useEffect(() => {
    if (game) {
      let storeUrl;
      if (isNaN(game.appId)) {
        storeUrl = 'https://play.google.com/store/apps/details?gl=US&id=' + game.appid;
      } else {
        storeUrl = 'https://apps.apple.com/us/app/' + game.appName + '/id' + game.appId;
      }

      setInfos({
        studioId: game.studioId,
        appName: game.appName,
        bundleId: game.bundleId,
        appId: game.appId,
        id: game.id,
        store: game.store || 'google',
        bizdevEmail: game.bizdevEmail?.trim(),
        category: game.category,
        tags: game.tags ? game.tags.split(',') : [],
        todoList: game.todoList.split(',').filter((e) => e !== ''),
        advertisingSdk: game.advertisingSdk,
        pageStoreUrl: storeUrl,
      });
    }
  }, [game, show]);

  const handleSubmitGame = () => {
    setLoading(true);
    const { pageStoreUrl, ...rest } = infos;
    updateDraftGame(camelToSnakeCaseObj(rest))
      .unwrap()
      .then(() => {
        confirmAlert({
          title: 'You have successfully updated the game',
          variant: 'success',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        setStep(1);
        console.log(err);
        setErrors({ error: err?.data?.message || 'Something went wrong' });
      });
  };

  const handleDelete = () => {
    confirmAlert({
      variant: 'warning',
      title: 'Are you sure you want to Delete the game?',
      desc: 'Deleted game can not be recovered!',
    }).then(async () => {
      const { bundleId, appId, studioId } = infos;
      setLoading(true);
      // Delete removed videos from cloud
      let promises = game.videos.map((el) => {
        if (el?.videoUrl !== '' && typeof el?.videoUrl === 'string') {
          const valArr = el?.videoUrl.split('/');
          if (studioId !== valArr[4]) return false;
          const fileUrl = `${valArr[3]}/${valArr[4]}/${valArr[5]}/${valArr[6]}`;
          return deleteCloud(fileUrl).unwrap();
        }
        return false;
      });
      await Promise.all(promises)
        .finally(() => {
          deleteDraftGame({
            bundle_id: bundleId,
            app_id: appId,
            studio_id: studioId,
          })
            .unwrap()
            .then(() => {
              if (onDelete) onDelete();
              handleClose();
            })
            .catch(() => {
              setStep(1);
              setLoading(false);
            });
        })
        .catch((error) => {
          // file may be missing from cloud so ignore it and delete from sql
          setErrors({
            error:
              error?.data?.message || error?.message || error?.error || 'Something went wrong will deleting your game',
          });
        });
    });
  };

  const handlePrev = () => {
    setStep(step - 1);
    setErrors({});
  };
  const handleNext = () => {
    if (step === 1) {
      const { error } = infosViewSchema.validate(camelToSnakeCaseObj(infos));
      if (error) {
        setErrors(formatJoiErrorsArray(error.details));
        return false;
      }
      return setStep(2);
    }
    if (step === 2 && infos.todoList.length < 4) {
      setErrors({ error: 'All fields are mandatory.' });
      return false;
    }
    return handleSubmitGame();
  };
  const handleClose = () => {
    setShow(false);
    setInfos(infosDefaultVal);
    setStep(1);
    setErrors({});
    setLoading(false);
  };
  return (
    <Restricted permission="draftGame.update">
      <Button type="button" variant="info" className="d-flex" onClick={() => setShow(true)}>
        <EditIcon color="currentColor" size={20} />
      </Button>

      <Modal backdrop="static" centered size={'md'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors?.error && !loading && (
            <div className="alert alert-danger" role="alert">
              {errors?.error}
            </div>
          )}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : step === 1 ? (
            <InfosView errors={errors} data={infos} setData={setInfos} />
          ) : (
            <SDKView data={infos} setData={setInfos} />
          )}
        </Modal.Body>

        <Modal.Footer className="d-flex">
          <div className="text-muted fs-7">Step {step} of 2</div>
          <div className="ms-auto">
            <Restricted permission="draftGame.delete">
              <Button
                disabled={loading}
                variant="danger"
                className="me-3 d-inline-flex"
                type="button"
                onClick={handleDelete}
              >
                <DeleteIcon size={20} color={'#fff'} />
                Delete
              </Button>
            </Restricted>
            {step > 1 && (
              <Button disabled={loading} variant="light" className="me-3" type="button" onClick={handlePrev}>
                Back
              </Button>
            )}
            <Button disabled={loading} variant="primary" type="button" onClick={handleNext}>
              {step === 2 ? 'Update' : 'Next'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Restricted>
  );
}

export default UpdateDraftGameModal;
