import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import GameAnalyticsStudioID from '../../../../UserManagement/CreateStudio/GameAnalyticsStudioID';
import { useCreateGameAnalyticsGameMutation, useUpdateStudioMutation } from '../../../../../data/api/studioApi';
import { confirmAlert } from '../../../../../Components/ConfirmModal';
import Loader from '../../../../../Components/Loader';
import { Restricted } from '../../../../../Components/permissions/UserPermissions';
import InviteUsers from './InviteUsers';
import UpdateGameAnalyticsGameIdModal from './UpdateGameAnalyticsGameIdModal';

const GameAnalyticsModal = ({ data, foundStudio }) => {
  const [showGaModal, setShowGaModal] = useState(false);
  const [gaId, setGaId] = useState(data?.gaId || '');
  const [createGa, { isLoading: isCreatingGa }] = useCreateGameAnalyticsGameMutation();
  const [updateStudio, { isLoading: isUpdatingStudio }] = useUpdateStudioMutation();
  const initialGaStudioId = foundStudio?.gaStudioId;

  const handleGameGa = () => {
    const payload = {
      id: data.id,
      appName: data.appName,
      os: data.os,
      store: data.store,
      bundleId: data.bundleId,
      studioId: data.studioId,
      gaId: gaId,
    };
    createGa(payload)
      .unwrap()
      .then(() => {
        setShowGaModal(false);
      })
      .catch((err) => {
        const errorDescription = (typeof err?.data?.details === 'string') ? err?.data?.details : err?.data?.details?.map((e) => e.detail || e.title).join(', ') || undefined;
        confirmAlert({
          variant: 'danger',
          title: err?.data?.message || 'Game published but GameAnalytics_ID not created!',
          desc: errorDescription,
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      });
  };
  const handleGaModalSubmit = () => {
    updateStudio({
      id: foundStudio?.studioId,
      ga_studio_id: gaId,
    })
      .unwrap()
      .then(() => {
        setShowGaModal(false);
        handleGameGa();
      })
      .catch((err) => {
        confirmAlert({
          variant: 'danger',
          title: err?.data?.message || 'Failed to update Game Analytics Studio ID',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      });
  };
  const handleButtonClick = () => {
    if (initialGaStudioId) {
      handleGameGa();
    } else {
      setShowGaModal(true);
    }
  };
  return (
    <>
      {data?.gaId ? (
        <div className="d-flex">
          {data.gaId}
          <Restricted permission="draftGame.publish">
            <InviteUsers gaGameId={data.gaId} gaStudioId={initialGaStudioId} />
          </Restricted>
          <Restricted permission="ga.update">
            <UpdateGameAnalyticsGameIdModal gameId={data.id} gaGameId={data.gaId} studioId={data?.studioId} />
          </Restricted>
        </div>
      ) : (
        <Restricted permission="draftGame.publish">
          <Button onClick={handleButtonClick} size="sm" disabled={isCreatingGa || isUpdatingStudio}>
            {isCreatingGa || isUpdatingStudio ? (
              <Loader parentStyle="me-2" size={20} color="#3F96C7" />
            ) : (
              'Create Game Analytics ID'
            )}
          </Button>
        </Restricted>
      )}

      <Modal show={showGaModal} onHide={() => setShowGaModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Game Analytics ID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isUpdatingStudio ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : (
            <>
              <p>
                You need to set up a Game Analytics ID for this game. Please select an existing one or create a new one.
              </p>
              <GameAnalyticsStudioID
                value={gaId}
                onChange={(newID) => setGaId(newID)}
                studioName={foundStudio?.name || ''}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowGaModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleGaModalSubmit} disabled={!gaId || isUpdatingStudio}>
            {isUpdatingStudio ? 'Saving...' : 'Save and Continue'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GameAnalyticsModal;
