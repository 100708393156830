import React, { useState } from 'react';
import HeaderInfoCard from '../../_Components/HeaderInfoCard';
import EarthIcon from '../../../../Components/Icons/EearthIcon';
import Form from 'react-bootstrap/Form';
import AbTestStatusDropdown from '../_Components/AbTestStatusDropdown';
import AbTestQualityDropdown from '../_Components/AbTestQualityDropdown';
import CohortParamsInfo from '../../_Components/CohortParamsInfo';
import EditIcon from '../../../../Components/Icons/EditIcon';
import CopyIcon from '../../../../Components/Icons/CopyIcon';
import FolderIcon from '../../../../Components/Icons/FolderIcon';
import UpdateConfigModal from '../../UpdateAbTestModals/UpdateConfigModal';
import { useParams } from 'react-router-dom';
import CreateConfigModal from '../../CreateAbTestModal/Config/CreateConfigModal';
import { Restricted, useUserPermission } from '../../../../Components/permissions/UserPermissions';
import GameOsIcons from '../../_Components/GameOsIcons';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../../data/slices/studio';
import CardIcon from '../../../../Components/Icons/CardIcon';

function ConfigRow({ item, itemIndex }) {
  const [editModal, setEditModal] = useState(false);
  const [copyModal, setCopyModal] = useState(null);
  const abtestQualityUpdatePermission = useUserPermission('quality.update');
  const updateConfigPermission = useUserPermission('abTest.update');
  const checkConfigPermission = useUserPermission('abTest.update.withDeviceId');
  const { id } = useParams();
  const foundGame = useSelector(getGameById(id));
  return (
    <>
      <div key={itemIndex} className="d-flex mb-2 pb-1 flex-column flex-xxl-row info-wrapper p-2 gap-2">
        <div className="config-container flex-grow-1 flex-basis-0 ">
          <div className="d-flex flex-column gap-1">
            <div className="fs-5">{item.name}</div>
            <div className="d-flex align-items-center gap-2">
              <GameOsIcons item={item} width="40px" height="35px" />
              <div className=" me-2" onClick={(event) => event.stopPropagation()}>
                {item.status && (
                  <Form.Group className="select-dropdown">
                    <AbTestStatusDropdown data={item} type="CONFIG" />
                  </Form.Group>
                )}
              </div>
              {abtestQualityUpdatePermission ? (
                <div onClick={(event) => event.stopPropagation()}>
                  <Form.Group className="select-dropdown">
                    <AbTestQualityDropdown type="CONFIG" data={item} />
                  </Form.Group>
                </div>
              ) : null}
            </div>
            {item?.store && (
              <HeaderInfoCard classname="mb-2 mt-1" title="Store" icon={<CardIcon />}>
                {item?.store}
              </HeaderInfoCard>
            )}
            {item.country && (
              <HeaderInfoCard title="country" icon={<EarthIcon />}>
                <span className="d-inline-block text-truncate w-75">{item.country}</span>
              </HeaderInfoCard>
            )}
            {item.network && (
              <HeaderInfoCard title="networks" icon={<EarthIcon />}>
                {item.network.split(',').map((network, i) => (
                  <li className="d-inline-block text-truncate w-75" key={i}>
                    • {network}
                  </li>
                ))}
              </HeaderInfoCard>
            )}
            {item.campaigns && (
              <HeaderInfoCard title="campaigns" icon={<FolderIcon />}>
                {JSON.parse(item.campaigns).map((campaign, i) => (
                  <span className="d-inline-block text-truncate w-75" key={i}>
                    • {campaign}
                  </span>
                ))}
              </HeaderInfoCard>
            )}
          </div>
        </div>
        <div className="flex-grow-1 flex-basis-0 ">
          <div className="d-flex flex-column align-items-end w-100">
            <div className="bg-light-400 h-100 w-100 rounded px-2 py-1 d-flex flex-wrap flex-grow-1 overflow-auto">
              <CohortParamsInfo cohort={item} />
            </div>
            <div className="d-flex align-items-center">
              <div className="text-secondary fs-7">updated by: {item?.updatedBy}</div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {foundGame?.status !== 'ARCHIVED' && (
                  <div className="d-flex ms-2 action-btn">
                    {(updateConfigPermission || (checkConfigPermission && item.deviceId)) && (
                      <div
                        className="btn btn-outline-info btn-sm p-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditModal(item);
                        }}
                        id="edit-config"
                      >
                        <EditIcon color={'currentColor'} size={20} />
                      </div>
                    )}
                    <Restricted permission="abTest.create">
                      <div
                        className="btn btn-outline-secondary btn-sm p-1 ms-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCopyModal(item);
                        }}
                      >
                        <CopyIcon size={20} />
                      </div>
                    </Restricted>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {editModal && <UpdateConfigModal abTest={editModal} show onClose={() => setEditModal(false)} />}
      {copyModal && (
        <CreateConfigModal
          id={id}
          onClose={() => {
            setCopyModal(null);
          }}
          initialState={{
            id: foundGame?.id,
            country: copyModal?.country?.split(',').filter(Boolean) || [],
            build: copyModal?.build || '',
            device: copyModal?.deviceId || '',
            os: copyModal?.os || 'all',
            name: 'Copy of ' + copyModal.name,
            params: copyModal?.params,
          }}
        />
      )}
    </>
  );
}

export default ConfigRow;
