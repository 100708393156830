import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useUpdateFacebookAppIDMutation, useUpdateDraftGameFacebookAppIDMutation } from '../../../../../data/api/studioApi';
import { confirmAlert } from '../../../../../Components/ConfirmModal';
import EditIcon from '../../../../../Components/Icons/EditIcon';

const EditFbAppModal = ({ foundStudio, data }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [fbAppId, setFbAppId] = useState(data.fbAppId || '');
  const [updateFacebookAppID, { isLoading: isUpdatingFbAppID }] = useUpdateFacebookAppIDMutation();
  const [updateDraftGameFacebookAppID, { isLoading: isUpdatingDraftGameFbAppID }] = useUpdateDraftGameFacebookAppIDMutation();

  const handleFbModalSubmit = (e) => {
    e.stopPropagation();
    if (!fbAppId) {
      confirmAlert({
        variant: 'danger',
        title: 'Required fields are empty',
        confirmBtn: false,
        cancelText: 'Ok',
      }).catch(() => {});
      return;
    }
    const updateData = {
      fb_app_id: fbAppId,
      studio_id: foundStudio?.studioId,
      bundle_id: data.bundleId,
      store: data.store === 'amazon' ? ['amazon'] : [],
    };
    (data?.isDraft ? updateDraftGameFacebookAppID(updateData) : updateFacebookAppID(updateData))
      .unwrap()
      .then(() => {
        setShowEditModal(false);
        confirmAlert({
          variant: 'success',
          title: 'Facebook App ID was successfully updated',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      })
      .catch((err) => {
        confirmAlert({
          variant: 'danger',
          title: err?.data?.message || 'Failed to update Facebook App ID',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch(() => {});
      });
  };

  const handleEditButtonClick = () => {
    setShowEditModal(true);
  };

  return (
    <>
      <Button
        variant="outline-light"
        size="sm"
        className="p-1 text-nowrap"
        onClick={handleEditButtonClick}
        id="fb-modal-open-button"
      >
        <EditIcon color="currentColor" size={18} />
      </Button>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Facebook App ID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please update the Facebook App ID for this game:</p>
          <Form>
            <Form.Group controlId="facebookAppId">
              <Form.Label>Facebook App ID</Form.Label>
              <Form.Control
                type="text"
                value={fbAppId}
                onChange={(e) => setFbAppId(e.target.value)}
                placeholder="Enter Facebook App ID"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleFbModalSubmit} disabled={!fbAppId || isUpdatingFbAppID || isUpdatingDraftGameFbAppID}>
            {(isUpdatingFbAppID || isUpdatingDraftGameFbAppID) ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditFbAppModal;
