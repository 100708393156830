import { generatePath, useHistory } from 'react-router-dom';
import moment from 'moment';
import TapNationPng from '../../../../assets/logoTapNation/LogoTapNation40.png';
import { R_GAME_METRICS, R_GAME_DRAFT } from '../../../../constants/routes';
import GameStatusDropdown from './GameStatusDropdown';
import TapNationLogo from '../../../../assets/logoTapNation/LogoTapNation40.png';
import GameOsIcons from '../../../AbTest/_Components/GameOsIcons';
const GameCard = (props) => {
  const history = useHistory();

  const handleSeeDetails = () => {
    history.push(generatePath(props.data.status === 'DRAFT' ? R_GAME_DRAFT : R_GAME_METRICS, { id: props.data.id }));
  };

  return (
    <tr key={props.value?.id}>
      <td onClick={handleSeeDetails} className="cursor-pointer" id="games-list">
        <div className="d-flex">
          <div className="overflow-hidden text-nowrap me-3 flex-shrink-0" style={{ width: 56 }}>
            <img
              width={56}
              height={56}
              className="rounded img-fit-contain"
              src={props.data.logoUrl === null || props.data.logoUrl === '' ? TapNationPng : props.data.logoUrl}
              alt={props.data.appName}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = TapNationLogo;
              }}
            />
          </div>
          <div>
            <h6>{props.data.appName}</h6>

            <div className="d-flex align-items-center flex-wrap">
              <span className="me-1 mb-1">
                <GameOsIcons
                  item={{ ...props.data, os: props.data.multipleOs ? '' : props.data.os }}
                  width="35px"
                  height="30px"
                />
              </span>
              {props.data.category ? (
                <span className="badge fs-7 me-1 mb-1 light-button px-3 py-2">{props.data.category}</span>
              ) : null}

              {props.data.tags &&
                props.data.tags.split(',').map((el) => (
                  <span key={el} className="badge fs-7 me-1 mb-1 light-button px-3 py-2">
                    {el}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </td>
      <td>
        <p className="fs-7 m-0 text-nowrap">Creation: {moment(props.data.createdAt).format('ll')}</p>
        <p className="fs-7 m-0 text-nowrap">Update: {moment(props.data.updateTime).format('ll')}</p>
      </td>
      <td onClick={(e) => e.stopPropagation()}>
        <div className="d-flex flex-basis-0 flex-shrink-1 flex-grow-1">
          <GameStatusDropdown
            store={props.data.store}
            value={props.data.status}
            bundleId={props.data.bundleId}
            studioId={props.data.studioId}
          />
        </div>
      </td>

      <td data-label="CPI" className=" text-center">
        <div className="badge text-dark-400">
          {props.data.avgCpi > 0 ? <span>{props.data.avgCpi.toFixed(2) + ' $'}</span> : 'N/A'}
        </div>
      </td>

      <td data-label="D1" className=" text-center">
        <div className="badge  text-dark-400">
          {props.data.avgRD1 > 0 ? (props.data.avgRD1 * 100).toFixed(1) + ' %' : 'N/A'}
        </div>
      </td>
      <td data-label="D7" className=" text-center">
        <div className="badge text-dark-400">
          {props.data.avgRD7 > 0 ? (props.data.avgRD7 * 100).toFixed(1) + ' %' : 'N/A'}
        </div>
      </td>
    </tr>
  );
};

export default GameCard;
