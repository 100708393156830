import React, { useState } from 'react';
import { Button, Col, InputGroup, Form, Modal } from 'react-bootstrap';
import InfoIcon from '../../Components/Icons/InfoIcon';
import CheckmarkIcon from '../../Components/Icons/CheckmarkIcon';
import { useCreateUserStudioMutation, useRefreshUserDataMutation, useRequestJoinStudioMutation } from '../../data/api/userApi';

const NoStudioModal = ({ show, existingStudioName: savedExistingStudioName }) => {
  const [createUserStudio, { isLoading: isCreatingNewStudio }] = useCreateUserStudioMutation();
  const [requestJoinStudio, { isLoading: isRequestingStudio }] = useRequestJoinStudioMutation();
  const [refreshUserData, {}] = useRefreshUserDataMutation();

  const FORM_TITLES = {
    OLD: 'Request to join existing Studio',
    NEW: 'Create new Studio',
  }

  const FORM_ACTION_TITLES = {
    OLD: 'Request to Join',
    NEW: 'Create new Studio',
  }

  const [actionTypeChecked, setActionTypeChecked] = useState(!!savedExistingStudioName);

  const [showModal, setShowModal] = useState(show);
  const [studioName, setStudioName] = useState(savedExistingStudioName);
  
  const [actionSuccess, setActionSuccess] = useState('');

  const [actionError, setActionError] = useState('');

  const handleCloseModal = (e) => {
    setTimeout(() => {
      setShowModal(false);
    }, 3000);
  }

  const handleAction = async (e) => {
    try {
      setActionError('');
      if (actionTypeChecked) {
        // Old
        await requestJoinStudio({
          studioName,
        }).unwrap();
        setActionSuccess('Successfully sent request to join studio!!!');
      } else {
        // New
        await createUserStudio({
          studioName,
        }).unwrap();

        await refreshUserData({});
        setActionSuccess('Successfully created studio!!!');
      }
      handleCloseModal();
    } catch (error) {
      setActionError(e?.data?.message || e.message || 'Something went wrong');
    }
  }

  const handleStudioTextChange = (text) => {
    setStudioName(text);
  };

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>You don't have a studio!!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="g-3 mt-2" onSubmit={handleAction}>
            <h5>{ actionTypeChecked ? FORM_TITLES.OLD : FORM_TITLES.NEW }</h5>
            <Form.Group as={Col} xs={12}>
              {actionSuccess && (
                <div className="alert alert-success mb-3 d-flex align-items-center fs-7" role="alert">
                  <div className=" me-3">
                    <CheckmarkIcon size={16} />
                  </div>
                  <div>{actionSuccess}</div>
                </div>
              )}
              {actionError && (
                <div className="alert alert-danger mb-3 d-flex align-items-center fs-7" role="alert">
                  <div className="me-3">
                    <InfoIcon />
                  </div>
                  <div>
                    <div>{actionError}</div>
                  </div>
                </div>
              )}
              <Form.Label className="fs-7 text-black fw-bold">Studio Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  className="fs-6"
                  size="lg"
                  aria-describedby="Studio Name"
                  placeholder="Type studio name"
                  value={studioName}
                  onChange={(text) => handleStudioTextChange(text.target.value)}
                  isInvalid={!!actionError}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {actionError}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mt-2">
                <Form.Check
                  type="checkbox"
                  checked={actionTypeChecked}
                  id="join-existing-studio"
                  label="Join Existing Studio"
                  onChange={(e) => setActionTypeChecked(e.target.checked)}
                />
              </InputGroup>
            </Form.Group>
            <Col xs={12} className='mt-4'>
              <Button variant="primary" onClick={handleAction} disabled={!studioName}>
                {(isRequestingStudio || isCreatingNewStudio) ? 'Processing..' : `${actionTypeChecked ? FORM_ACTION_TITLES.OLD : FORM_ACTION_TITLES.NEW}`}
              </Button>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NoStudioModal;
