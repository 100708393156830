import { forwardRef } from "react";
import { Dropdown } from "react-bootstrap";

function MenuDropdown({ title, options = [] }) {
  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </div>
  ));
  
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
      </Dropdown.Toggle>
      <Dropdown.Menu size="sm" title=""> 
        <Dropdown.Header>{ title }</Dropdown.Header>
        {
          options?.map((option) => (
            <Dropdown.Item>
              { option }
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default MenuDropdown;