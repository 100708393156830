import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useListCampaignsQuery } from '../../../../../data/api/fbAdsApi';
import SearchableDropdown from '../../../../../Components/SearchableDropdown';
import Loader from '../../../../../Components/Loader';
import CampaignStatusToggle from '../CampaignStatusToggle';

const CampaignList = ({ state, setState }) => {
  const clientID = state.client?.id;
  const { data: campaigns, isFetching: isLoadingCampaigns } = useListCampaignsQuery(clientID, { skip: !clientID });

  const sortedCampaigns = useMemo(() => {
    if (!campaigns) return [];
    return [...campaigns]
      .sort((x, y) => x.effective_status.localeCompare(y.effective_status))
      .map((campaign) => ({
        value: campaign.id,
        label: `${campaign.name} (${campaign.effective_status})`,
      }));
  }, [campaigns]);

  const getCampaignById = (id) => {
    return campaigns.find((campaign) => campaign.id === id);
  };

  const handleSelectChange = (selectedIds) => {
    setState({ selectedCampaign: getCampaignById(selectedIds[0]), hasChanged: true });
  };

  if (isLoadingCampaigns) return <Loader parentStyle="loader" size={23} color={'#3F96C7'} />;

  if (!campaigns?.length) return 'No campaigns found.';

  return (
    <Form.Group controlId="campaignSelect" xs={6} className="d-flex align-items-center gap-2">
      <SearchableDropdown
        writeSelected={true}
        allowClear={false}
        selected={[state.selectedCampaign?.id]}
        onSelect={handleSelectChange}
        options={sortedCampaigns}
        placeholder={'Select a Campaign'}
      />
      {state.selectedCampaign && <CampaignStatusToggle campaign={getCampaignById(state.selectedCampaign.id)} />}
    </Form.Group>
  );
};

export default CampaignList;
