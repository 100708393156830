import { useState, useEffect } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useUpdateCampaignMutation } from '../../../../data/api/fbAdsApi';

const CampaignStatusToggle = ({ campaign }) => {
  const [updateCampaignMutation, { isLoading }] = useUpdateCampaignMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const isActive = campaign?.effective_status === 'ACTIVE';

  useEffect(() => {
    setErrorMessage('');
  }, [campaign]);

  const handleToggle = (e) => {
    e.preventDefault();

    updateCampaignMutation({
      id: campaign.id,
      status: isActive ? 'PAUSED' : 'ACTIVE',
    })
      .unwrap()
      .then((response) => {
        if (response.error) {
          setErrorMessage(
            response.error?.errorUserTitle ||
              response.error?.errorUserMsg ||
              response.error?.message ||
              'An error occured.',
          );
        }
      })
      .catch(() => {
        setErrorMessage('An error occured.');
      });
  };

  if (!campaign) return '';

  if (errorMessage)
    return (
      <Alert
        variant={'danger'}
        className="py-1 mb-0"
        dismissible={true}
        onClose={() => {
          setErrorMessage('');
        }}
      >
        <span style={{ fontSize: 'medium' }}>{errorMessage}</span>
      </Alert>
    );

  return (
    <>
      <Button size="sm" onClick={handleToggle} disabled={isLoading}>
        {isLoading ? 'Saving...' : isActive ? 'Pause' : 'Activate'}
      </Button>
    </>
  );
};

export default CampaignStatusToggle;
