import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useListStudioJoinRequestsQuery, useUpdateStudioJoinRequestMutation } from '../../data/api/studioApi';
import Pagination from '../../Components/Pagination';
import Loader from '../../Components/Loader';
import { useQuery } from '../../utils/customHooks';
import TnTable from '../../Components/Table/TnTable';
import moment from 'moment';
import TnTableFiltersContainer from '../../Components/Table/TnTableFiltersContainer/TnTableFiltersContainer';

const StudioJoinRequestList = () => {
  const query = useQuery();
  const initialSearch = query.get('search') || '';
  const perPage = 20;
  const [page, setPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState('PENDING');
  const [filteredRequests, setFilteredRequests] = useState([]);
  const { data, isFetching: isLoading, refetch } = useListStudioJoinRequestsQuery({ status: statusFilter });

  const [ updateStudioJoinRequest ] = useUpdateStudioJoinRequestMutation();
  const [filters, setFilters] = useState([
    { name: 'search', type: 'search', value: initialSearch, placeholder: 'Search Users...' },
    {
      name: 'status',
      type: 'select',
      label: 'Status',
      options: [
        { label: 'Pending', value: 'PENDING' },
        { label: 'Rejected', value: 'REJECTED' },
        { label: 'Approved', value: 'APPROVED' },
      ],
      value: statusFilter,
    },
  ]);

  useEffect(() => {
    filters.forEach((filter) => {
      switch(filter.name) {
        case 'status':
          setStatusFilter(filter.value);
          break;
        default:
          break;
      }
    })
  }, [filters])

  useEffect(() => {
    if (data) {
      let filteredData = data.filter((studioRequest) => {
        let valid = true;
        filters.forEach((filter) => {
          if (filter.value) {
            switch (filter.name) {
              case 'search':
                const searchValue = filter.value.toLowerCase();
                valid =
                  valid &&
                  (studioRequest?.studioName?.toLowerCase().includes(searchValue) ||
                  studioRequest?.firstName?.toLowerCase().includes(searchValue) ||
                  studioRequest?.lastName?.toLowerCase().includes(searchValue) ||
                  studioRequest?.email?.toLowerCase().includes(searchValue));
                break;
              default:
                break;
            }
          }
        });
        return valid;
      });
      const transformedData = filteredData.map((studioRequest) => ({
        ...studioRequest,
        actions: <div>
          <Button type="button" variant="primary" className=" ms-auto" onClick={() => handleAction('APPROVE', studioRequest.id)}>
            APPROVE
          </Button>
          { studioRequest?.status === 'PENDING' &&
            <Button type="submit" variant="outline-warning" className=" ms-3" onClick={() => handleAction('REJECT', studioRequest.id)}>
              REJECT
            </Button>
          }
        </div>,
        requestedAt: moment(studioRequest.createdAt).format('ll'),
        studioName: studioRequest?.studioName,
        userEmail: studioRequest?.email,
        userName: `${studioRequest.firstName} ${studioRequest.lastName}`,
      }));

      setFilteredRequests(transformedData);
    }
  }, [data, filters]);

  const header = [
    { key: 'userName', label: 'User Name', sortable: true },
    { key: 'userEmail', label: 'E-mail', sortable: true },
    { key: 'studioName', label: 'Studio Name', sortable: true },
    { key: 'requestedAt', label: 'Requested At', sortable: true },
    { key: 'actions', label: '' },
  ];

  const handleAction = async (action, studioJoinRequestid) => {
    try {
      await updateStudioJoinRequest({
        action,
        id: studioJoinRequestid,
      }).unwrap();
      await refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 d-flex flex-lg-row flex-column gap-1 align-items-lg-center">
          {isLoading ? null : (
            <div className="d-flex gap-1">
              <TnTableFiltersContainer
                filters={filters}
                setFilters={(s) => {
                  setFilters(s);
                  setPage(1);
                }}
              />
            </div>
          )}
          <div className="ms-auto d-flex align-items-center">
            <Pagination
              total={filteredRequests?.length || 0}
              perPage={perPage}
              page={page}
              pushHistory={false}
              onChange={(currentPage) => {
                setPage(currentPage);
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
            </div>
          ) : filteredRequests?.length ? (
            <div className="table-responsive">
              <TnTable data={filteredRequests} page={page} perPage={perPage} header={header} />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <span className="text-dark me-2">No results found.</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StudioJoinRequestList;
